import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { formatEther, parseEther } from '@ethersproject/units';
import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import { isAddress } from '@ethersproject/address';
import { Contract } from '@ethersproject/contracts';
import { BigNumber } from '@ethersproject/bignumber';
import { Line } from 'rc-progress';

import { getEtherscanLink } from '../lib/utils';
import getReceipt from '../lib/getReceipt';
import { addToast, PendingIPFS, SuccessfulIPFS } from '../hooks/useToast';

import {JsonRpcProvider} from "@ethersproject/providers";
import ABI from '../constants/abi/mintyFactory.json';
import ERC_ABI from '../constants/abi/erc721.json';
import NFT_ABI from '../constants/abi/nfttoken.json';

import { PINATA_API_KEY, PINATA_SECRET, MINTY_FACTORY } from '../constants';
import pinataSDK from '@pinata/sdk';

const Mint = () => {
   const [market, setMarket] = useState(true);

   //getting the contract of minty
   const contract = MINTY_FACTORY;
   const library_infura = new JsonRpcProvider("https://rpc-mainnet.maticvigil.com/v1/fcb5333fa036d7a20e56ebdcfc2d2ba4fd9f5cd8\n")
   const { account, library } = useWeb3React();

   const pinata = pinataSDK(PINATA_API_KEY, PINATA_SECRET);

   const [first_loop, set_first_loop] = useState(0);
   const [mintmode1, set_mintmode1] = useState('PINATA');
   const [button_enabled, set_button_enabled] = useState(true);

   //minty
   const [tokenName1, setTokenName1] = useState('');
   const [tokenSymbol1, setTokenSymbol1] = useState('');
   const [tokenCost1, setTokenCost1] = useState('');
   const [tokenCap1, setTokenCap1] = useState('');

   //const [pinataName, setPinataName] = useState('');
   //const [pinataTitle, setPinataTitle] = useState('');
   const [pinataUrl, setPinataUrl] = useState('');
   const [pinataDesc, setPinataDesc] = useState('');
   const [pinataImg, setPinataImg] = useState('');
   const [pinataUploadImg, setPinataUploadImg] = useState('');
   const [customURI, setCustomURI] = useState('');
   const [unlocktext, set_unlocktext] = useState('Please Unlock Wallet');

   //new for details
   const [tokenAddress, setTokenAddress] = useState('');

   const [mintmode, set_mintmode] = useState('NONE');

   //minty
   const [tokenName, setTokenName] = useState('');
   const [tokenSymbol, setTokenSymbol] = useState('');
   const [tokenCost, setTokenCost] = useState('0');
   const [tokenCap, setTokenCap] = useState('');
   const [tokenSupply, setTokenSupply] = useState('');
   const [tokenBalance, setTokenBalance] = useState('');

   const [tokenProgress, setTokenProgress] = useState('');

   const [tokenCreator, setTokenCreator] = useState('');
   // const [tokenUrl, setTokenUrl] = useState('');
   const [tokenImg, setTokenImg] = useState('');
   const [tokenJsonUrl, setTokenJsonUrl] = useState('');
   const [buyAmount, setBuyAmount] = useState(1);

   const [newTokenCost, setNewTokenCost] = useState('0');

   const [filetype, setfiletype] = useState('image');

   const location = useLocation();
   const history = useHistory();

   // Start from url parameter
   useEffect(() => {
      const address = location.search.split('?address=')[1];
      if (address) {
         setMarket(true);
         setTokenAddress(address);
         checkTokenDetails(address);
      }
      // eslint-disable-next-line
   }, [location]);

   // effect hook for updating data
   useEffect(() => {
      // update the ui elements
      async function updateUIStates() {
         if (
            tokenName1 !== '' &&
            tokenSymbol1 !== '' &&
            tokenCost1 !== '' &&
            tokenCap1 !== ''
         ) {
            set_button_enabled(false);
            set_unlocktext('Minty your Token');
         } else {
            set_button_enabled(true);
            set_unlocktext('Please enter all values');
         }
      }

      // fix for updating after wallet login
      if (account && first_loop === 0) {
         set_first_loop(1);
         set_button_enabled(true);
         set_unlocktext('Please enter all values');
         updateUIStates();
      }

      // schedule every 15 sec refresh
      const timer = setInterval(() => {
         if (account) {
            updateUIStates();
         }
      }, 500);

      // clearing interval
      return () => clearInterval(timer);
      // eslint-disable-next-line
   }, [account, library, tokenName1, tokenSymbol1, tokenCost1, tokenCap1]);

   function toggleMode() {
      if (mintmode1 === 'OWN') {
         set_mintmode1('PINATA');
      } else {
         set_mintmode1('OWN');
      }
   }

   function handleChangeTokenName(event) {
      const values = event.target.value;
      setTokenName1(values);
   }

   function handleChangeTokenSymbol(event) {
      const values = event.target.value;
      setTokenSymbol1(values);
   }

   function handleChangeTokenCost(event) {
      const values = event.target.value;
      setTokenCost1(values);
   }

   function handleChangeTokenCap(event) {
      const values = event.target.value;
      setTokenCap1(values);
   }

   function handleChangeCustomURI(event) {
      const values = event.target.value;
      setCustomURI(values);
   }

   function handleChangePinataUrl(event) {
      const values = event.target.value;
      setPinataUrl(values);
   }

   function handleChangePinataDesc(event) {
      const values = event.target.value;
      setPinataDesc(values);
   }

   function handleChangePinataImg(event) {
      const values = event.target.value;
      setPinataImg(values);
   }

   function handleChangePinataUploadImg(event) {
      const values = event.target.files[0];
      console.log(values);
      setPinataUploadImg(values);
      setPinataImg('');
   }

   // minty function call
   const callMinty = async () => {
      const ipfsPrefix = 'https://gateway.ipfs.io/ipfs/';
      let nft_url = '';
      let pinata_image_url = '';

      console.log(tokenName1);
      console.log(tokenSymbol1);
      console.log(tokenCost1);
      console.log(tokenCap1);
      console.log(customURI);
      console.log(account);

      console.log(mintmode1);

      // we have an upload image
      if (pinataUploadImg !== '') {
         console.log('in ipfs file upload start');
         let data = new FormData();
         data.append('file', pinataUploadImg);
         const url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';

         addToast({
            body: <PendingIPFS Upload file to IPFS />,
            type: 'loading',
            hideAfter: 1
         });

         const uploader = await axios.post(url, data, {
            maxContentLength: 'Infinity', //this is needed to prevent axios from erroring out with large files
            headers: {
               'Content-Type': `multipart/form-data;`,
               pinata_api_key: PINATA_API_KEY,
               pinata_secret_api_key: PINATA_SECRET
            }
         });

         console.log(uploader);

         if (uploader.status === 200) {
            pinata_image_url = uploader.data.IpfsHash;
            console.log(pinata_image_url);
            addToast({
               body: (
                  <SuccessfulIPFS Upload file to IPFS nft={pinata_image_url} />
               ),
               type: 'success'
            });
         }
      }

      let json_pin = {};
      json_pin.title = tokenName1;
      json_pin.name = tokenName1;
      json_pin.description = pinataDesc;
      json_pin.url = pinataUrl;

      if (pinata_image_url !== '') {
         json_pin.image = pinata_image_url;
      } else {
         json_pin.image = pinataImg;
      }

      console.log(json_pin);

      if (mintmode1 === 'OWN') {
         nft_url = customURI;
      } else {
         // try to pin json
         try {
            addToast({
               body: <PendingIPFS Upload to IPFS />,
               type: 'loading',
               hideAfter: 2
            });
            const result = await pinata.pinJSONToIPFS(json_pin);
            console.log('pinned json');
            console.log(result.IpfsHash);
            let ipfs_hash = result.IpfsHash;
            nft_url = ipfsPrefix + ipfs_hash;
            console.log(nft_url);
            addToast({
               body: <SuccessfulIPFS Upload to IPFS nft={nft_url} />,
               type: 'success'
            });
         } catch (e) {
            addToast({ body: e.message, type: 'error' });
         }
      }

      try {
         //default ABI
         const minty_contract =
            isAddress(contract) && !!ABI && !!library
               ? new Contract(contract, ABI, library.getSigner(account))
               : undefined;

         const amount_eth = parseEther(tokenCost1);
         console.log(contract);

         minty_contract.once('NewMintyContract', (deployed, event) => {
            console.log('Contract event');
            console.log(deployed);

            history.push(`/mint?address=${deployed}`);
            //   router.push({pathname: "/market", query: {new_nft_address : deployed}});
         });

         const { hash } = await minty_contract.MintyMint(
            tokenName1,
            tokenSymbol1,
            nft_url,
            amount_eth,
            tokenCap1,
            account
         );
         await getReceipt(hash, library);
         // console.log('runs');
      } catch (e) {
         addToast({ body: e.message, type: 'error' });
      }
   };

   //format addresses in ui
   function format_address(address) {
      const new_address = address.substring(0, 5) + '...' + address.slice(-3);
      return new_address;
   }

   async function checkTokenDetails(queryAddress) {
      const address = queryAddress ? queryAddress : tokenAddress;

      console.log(address);
      const erc721_cont =
         isAddress(address) && !!ERC_ABI && !!library_infura
            ? new Contract(address, ERC_ABI, library_infura)
            : undefined;
      // console.log(await erc721_cont.supportsInterface(0x80ac58cd));

      const { hide: hidePending } = addToast({
         body: 'Checking NFT Details',
         type: 'loading',
         hideAfter: 0
      });

      try {
         const check = await erc721_cont.supportsInterface(0x80ac58cd);
         console.log(address);

         if (check === true) {
            const ipfsPrefix = 'https://dweb.link/ipfs/';
            const nft_cont =
               isAddress(address) && !!NFT_ABI && !!library_infura
                  ? new Contract(address, NFT_ABI, library_infura)
                  : undefined;
            console.log(nft_cont);

            const tname = await nft_cont.name();
            const tsymbol = await nft_cont.symbol();
            const towner = await nft_cont.owner();
            const turl = await nft_cont.url();
            const totalsupply = await nft_cont.totalSupply();
            const tcap = await nft_cont.tokenCap();
            const tcost = await nft_cont.tokenCost();
            const tbalance = await library_infura.getBalance(nft_cont.address);

            console.log(turl);

            const res = await fetch(turl);
            console.log(res);

            const json = await res.json();

            console.log(ipfsPrefix + json.image);

            let response = await fetch(ipfsPrefix + json.image);
            let data = await response.blob();

            console.log(ipfsPrefix + json.image);
            console.log(data.type);

            if (data.type.includes('video')) {
               setfiletype('video');
            } else if (data.type.includes('audio')) {
               setfiletype('audio');
            } else {
               setfiletype('image');
            }

            setTokenImg(ipfsPrefix + json.image);
            setTokenJsonUrl(json.url);
            setTokenName(tname);
            setTokenSymbol(tsymbol);
            setTokenCreator(towner);
            // setTokenUrl(turl);

            setTokenCost(tcost);
            setTokenCap(tcap);
            setTokenSupply(totalsupply);
            setTokenBalance(tbalance);

            console.log(tcap);
            console.log(totalsupply);

            setTokenProgress(((tcap - totalsupply) / tcap) * 100);

            set_mintmode('CHECKED');

            hidePending();
            addToast({ body: 'Loading successful', type: 'success' });
         }
      } catch (e) {
         console.log(e);
         set_mintmode('NONE');
         hidePending();
         addToast({
            body: 'There was an error checking the token address',
            type: 'error'
         });
      }
   }

   function handleChangeBuyAmount(event) {
      const values = event.target.value;
      setBuyAmount(values);
   }

   // function handleChangeaddress(event) {
   //    const values = event.target.value;
   //    setTokenAddress(values);
   // }

   function handleChangeNewPrice(event) {
      const values = event.target.value;
      setNewTokenCost(values);
   }

   async function buyNft() {
      if (!account) {
         addToast({ body: 'Please Unlock Your Wallet', type: 'error' });
      } else {
         const amount = buyAmount;
         const nft_cont =
            isAddress(tokenAddress) && !!NFT_ABI && !!library
               ? new Contract(tokenAddress, NFT_ABI, library.getSigner(account))
               : undefined;
         console.log(nft_cont);

         if (amount < 1) {
            addToast({
               body: 'Please enter a valid buying quantity (1+)',
               type: 'error'
            });
         } else {
            const round_amount = Math.floor(amount);
            let array = [];
            let totalamount = BigNumber.from(0);

            let i;
            for (i = 0; i < round_amount; i++) {
               array.push(account);
               totalamount = totalamount.add(tokenCost);
            }
            console.log(array);
            console.log(round_amount);
            console.log(totalamount);

            const trans_obj = {
               // Required unless deploying a contract (in which case omit)
               //gasLimit: 300000,        // the maximum gas this transaction may spend
               value: totalamount // the amount (in wei) this transaction is sending
            };

            try {
               const { hash } = await nft_cont.mintM(
                  round_amount,
                  array,
                  trans_obj
               );
               await getReceipt(hash, library);
               await checkTokenDetails();
            } catch (e) {
               addToast({ body: e.message, type: 'error' });
            }
         }
      }
   }

   // withdraws the eth from contact to owner
   async function withdraw() {
      if (!account) {
         addToast({ body: 'Please Unlock Your Wallet', type: 'error' });
      } else {
         const nft_cont =
            isAddress(tokenAddress) && !!NFT_ABI && !!library
               ? new Contract(tokenAddress, NFT_ABI, library.getSigner(account))
               : undefined;

         try {
            const { hash } = await nft_cont.withdrawETH();
            await getReceipt(hash, library);
            await checkTokenDetails();
         } catch (e) {
            addToast({ body: e.message, type: 'error' });
         }
      }
   }

   // withdraws the eth from contact to owner
   async function changeprice() {
      console.log(newTokenCost);
      if (!account) {
         addToast({ body: 'Please Unlock Your Wallet', type: 'error' });
      } else if (newTokenCost <= 0) {
         addToast({ body: 'New Price must be greater than 0', type: 'error' });
      } else {
         const amount_eth = parseEther(newTokenCost);
         const nft_cont =
            isAddress(tokenAddress) && !!NFT_ABI && !!library
               ? new Contract(tokenAddress, NFT_ABI, library.getSigner(account))
               : undefined;

         try {
            const { hash } = await nft_cont.changeCost(amount_eth);
            await getReceipt(hash, library);
            await checkTokenDetails();
            setNewTokenCost(0);
         } catch (e) {
            addToast({ body: e.message, type: 'error' });
         }
      }
   }

   function copylink() {
      const el = document.createElement('textarea');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      el.setAttribute('readonly', '');
      const minty_uri = 'https://polygon.alchemydao.com/mint';
      const stringer = minty_uri + '?address=' + tokenAddress;
      el.value = stringer;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      addToast({ body: 'Copied Link To Your Clipboard', type: 'success' });
   }

   return (
      <div className='page'>
         <div className='page__container'>
            <h3 className='page__header'>MINT NFTS</h3>
            <div className='page__flex-container card-shadow'>
               <div className='mint__container centered'>
                  <div className='mint__container--inline-div'>
                     <h4 className='mint__container--header'>
                        {market
                           ? 'Check Minty minted Token'
                           : `Welcome to Minty, Mint your own NFT's`}
                     </h4>
                     <div
                        className='mint__container--buttons-container'
                        onClick={() => setMarket(!market)}
                     >
                        <div
                           onClick={() => setMarket(!market)}
                           className={
                              market
                                 ? 'mint__container--buttons-container--secondary move-right'
                                 : 'mint__container--buttons-container--secondary'
                           }
                        ></div>
                        <h4
                           className={
                              market
                                 ? 'mint__container--label mint__container--label-market'
                                 : 'mint__container--label mint__container--label-market color-gray'
                           }
                        >
                           MARKET
                        </h4>
                        <h4
                           className={
                              market
                                 ? 'mint__container--label color-gray'
                                 : 'mint__container--label'
                           }
                        >
                           MINT NFT
                        </h4>
                     </div>
                  </div>
                  {market && mintmode !== 'CHECKED' ? (
                     <div className='mint__form'>
                        <h3 className='form__label'>Token Address:</h3>
                        <input
                           type='text'
                           required
                           className='form__input mint__form--input'
                           onChange={e => setTokenAddress(e.target.value)}
                           value={tokenAddress || ''}
                        />
                        <button
                           className='form__button mint__form--button'
                           onClick={() => checkTokenDetails()}
                        >
                           GET TOKEN DETAILS
                        </button>
                     </div>
                  ) : (
                     !market && (
                        <div className='mint__form'>
                           <h3 className='form__label'>Token Name:</h3>
                           <input
                              required
                              className='form__input vote__form--input'
                              type='text'
                              onChange={e => handleChangeTokenName(e)}
                           />

                           <h3 className='form__label'>Token Symbol:</h3>
                           <input
                              className='form__input vote__form--input'
                              type='text'
                              onChange={e => handleChangeTokenSymbol(e)}
                           />

                           <h3 className='form__label'>Token Cost in MATIC:</h3>
                           <input
                              className='form__input vote__form--input'
                              type='number'
                              onChange={e => handleChangeTokenCost(e)}
                           />

                           <h3 className='form__label'>Token Cap:</h3>
                           <input
                              className='form__input vote__form--input'
                              type='number'
                              onChange={e => handleChangeTokenCap(e)}
                           />
                        </div>
                     )
                  )}
                  <div className='mint__form no-padding-top'>
                     {!market ? (
                        mintmode1 === 'PINATA' ? (
                           <>
                              <button
                                 className='dao__details-container--button vote__button--vote'
                                 type='button'
                                 onClick={() => toggleMode()}
                              >
                                 Provide my own NFT Token URI
                              </button>

                              <h3 className='form__label'>URL:</h3>
                              <input
                                 className='form__input vote__form--input'
                                 type='text'
                                 onChange={e => handleChangePinataUrl(e)}
                              ></input>

                              <h3 className='form__label'>Description:</h3>
                              <textarea
                                 className='form__input vote__form--input'
                                 type='text'
                                 onChange={e => handleChangePinataDesc(e)}
                              ></textarea>

                              <h3 className='form__label'>Media URL:</h3>
                              <input
                                 className='form__input vote__form--input'
                                 type='text'
                                 onChange={e => handleChangePinataImg(e)}
                                 value={pinataImg || ''}
                              ></input>
                              <h3 className='form__label'>
                                 Or Upload Media File:
                              </h3>
                              <input
                                 className='form__input vote__form--input'
                                 type='file'
                                 onChange={e => handleChangePinataUploadImg(e)}
                              ></input>
                           </>
                        ) : (
                           <>
                              <button
                                 className='dao__details-container--button vote__button--vote'
                                 type='button'
                                 onClick={() => toggleMode()}
                              >
                                 Use Minty's URI Hosting Service
                              </button>

                              <h3 className='form__label'>
                                 Enter your NFT Token URI:
                              </h3>
                              <input
                                 className='form__input vote__form--input'
                                 type='text'
                                 onChange={e => handleChangeCustomURI(e)}
                              ></input>
                           </>
                        )
                     ) : (
                        ''
                     )}
                  </div>
                  {!market && (
                     <button
                        className='dao__details-container--button vote__button--vote'
                        type='button'
                        onClick={callMinty}
                        disabled={button_enabled}
                     >
                        {unlocktext}
                     </button>
                  )}
                  {mintmode === 'CHECKED' && market ? (
                     <>
                        <div className='mint__top-container'>
                           <button
                              className='dao__details-container--button vote__button--vote dao__details-container--button-copy'
                              type='button'
                              onClick={() => copylink()}
                           >
                              Copy Link
                           </button>
                           <button
                              className='dao__back-button'
                              type='button'
                              onClick={() => {
                                 set_mintmode('NULL');
                              }}
                           >
                              Change Token
                           </button>
                        </div>
                        <h3 className='form__label'>
                           {tokenName} ({tokenSymbol})
                        </h3>

                        <a
                           href={getEtherscanLink(1, tokenAddress, 'ADDRESS')}
                           target='_blank'
                           rel='noreferrer'
                        >
                           <h3 className='form__label'>
                              {format_address(tokenAddress)}
                           </h3>
                        </a>

                        <div className='mint__image-container'>
                           {filetype === 'image' ? (
                              <img
                                 src={tokenImg}
                                 alt='NFT'
                                 className='object-contain w-full h-64'
                              />
                           ) : filetype === 'video' ? (
                              <video
                                 autoPlay
                                 loop
                                 muted
                                 playsInline
                                 controls={true}
                                 className='w-full object-contain items-center text-center'
                                 src={tokenImg}
                              >
                                 <source src={tokenImg} type='video/mp4' />
                                 Your browser does not support the video tag.
                              </video>
                           ) : (
                              <audio
                                 autoPlay
                                 loop
                                 muted
                                 controls={true}
                                 className='w-full object-contain items-center text-center'
                                 src={tokenImg}
                              >
                                 <source src={tokenImg} type='audio/mpeg' />
                                 Your browser does not support the audio tag.
                              </audio>
                           )}
                        </div>
                        <a
                           href={getEtherscanLink(1, tokenCreator, 'ADDRESS')}
                           target='_blank'
                           rel='noreferrer'
                        >
                           <h3 className='form__label'>
                              Token Creator: {format_address(tokenCreator)}
                           </h3>
                        </a>
                        <h3 className='form__label'>
                           {`Token cost: ${formatEther(tokenCost)} MATIC`}
                        </h3>

                        <a href={tokenJsonUrl} target='_blank' rel='noreferrer'>
                           <h3 className='form__label'>{tokenJsonUrl}</h3>
                        </a>
                        <h3 className='form__label'>
                           {tokenCap.toNumber() - tokenSupply.toNumber()} /{' '}
                           {tokenCap.toNumber()} Tokens Left
                        </h3>
                        <div className='mint__line-container'>
                           <Line
                              percent={[tokenProgress || 0, 100]}
                              strokeWidth='4'
                              trailWidth='4'
                              strokeColor={[
                                 '#08c4d1',
                                 {
                                    '100%': '#ffffff',
                                    '0%': '#08c4d1'
                                 }
                              ]}
                              strokeLinecap='round'
                           />
                        </div>
                        <h3 className='form__label'>Buy Amount</h3>
                        <input
                           required
                           className='form__input vote__form--input'
                           type='number'
                           min='0'
                           pattern='[0-9]{10}'
                           step='1'
                           value={buyAmount || ''}
                           onChange={e => handleChangeBuyAmount(e)}
                        />
                        <h3 className='form__label'>
                           Cost {buyAmount * formatEther(tokenCost)}
                        </h3>

                        <button
                           className='dao__details-container--button vote__button--vote'
                           type='button'
                           onClick={() => buyNft()}
                        >
                           Buy {buyAmount} {tokenSymbol}{' '}
                        </button>

                        {tokenCreator === account ? (
                           <div className='mint__form no-padding-top'>
                              <h3 className='form__label'>
                                 Current Contract Balance:{' '}
                                 {(formatEther(tokenBalance) / 100) * 99} MATIC
                              </h3>

                              <button
                                 className='dao__details-container--button vote__button--vote'
                                 type='button'
                                 onClick={() => withdraw()}
                              >
                                 Withdraw to Wallet
                              </button>

                              <h3 className='form__label'>
                                 Set a New NFT Price
                              </h3>
                              <input
                                 className='form__input vote__form--input'
                                 type='text'
                                 value={newTokenCost || ''}
                                 onChange={e => handleChangeNewPrice(e)}
                              />

                              <button
                                 className='dao__details-container--button vote__button--vote'
                                 type='button'
                                 onClick={() => changeprice()}
                              >
                                 Change NFT Price
                              </button>
                           </div>
                        ) : (
                           ''
                        )}
                     </>
                  ) : (
                     ''
                  )}
               </div>
            </div>
         </div>
      </div>
   );
};

export default Mint;

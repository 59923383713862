export const ACTIVE_NETWORK = 137;

export const ETHERSCAN_KEY = 'RC2K3GMCK38RSTFN5ZS97UTT39WXJ3WWCE';
export const DUTCH_FACTORY = '0xb63881f0Be29506B28386781C8603910396C75E9';
export const PINATA_API_KEY = 'f957939f2b22918a89b9';
export const PINATA_SECRET =
   '7dd4d5fdde247961941e8d8f2fbace35c34aadd4581c2c5ecf1acbdb98dc0f11';
export const MINTY_FACTORY = '0xe02eF7BeAc8c723a17545e0F9F2774Caf13466fc';

// config old
export const NFT_DAO_ADDRESS = '0x6CEF09dfed4e5467323a1b5f93f24F67dCE39602';
export const ALC_ADDRESS = '0x87B078cf94B188EFB9D2208cAE47A66ea7FEa09A';
export const STAKINGPOOL_LP = '0x12d916951a9da2763b55972364b0Bd0B726E2F43';
export const UNIV2_STAKING_ADDRESS =
   '0x367cb081C591bf1E9d6E74bc81e5BEDf971364aB';

export const AVERAGE_BLOCK_TIME_IN_SECS = 15;
export const PROPOSAL_LENGTH_IN_BLOCKS = 5760;
export const PROPOSAL_LENGTH_IN_SECS =
   AVERAGE_BLOCK_TIME_IN_SECS * PROPOSAL_LENGTH_IN_BLOCKS;

export const GARDEN_OPTIONS = [
   {
      tokenName: "ALCH",
      rewardName: "MATIC",
      tokenGardenName: "MATIC Fee Pool",
      tokenIconPath: "alchemy_square.png",
      tokenIconPathReward: "eth.png",
      descriptionTop: "Deposit ALCH, earn MATIC",
      poolAddress: "0x81692F5647ef63766F3A521114bc9ED094eC23cE",
      tokenAddress: "0x87B078cf94B188EFB9D2208cAE47A66ea7FEa09A",
      open : true
   },
   {
      tokenName: "ALCH-MATIC LP",
      rewardName: "ALCH",
      tokenGardenName: "LP Staking Pool",
      tokenIconPath: "alchemy_square.png",
      tokenIconPathReward: "alchemy_square.png",
      descriptionTop: "Deposit ALCH-MATIC LP, earn ALCH",
      poolAddress: "0x12d916951a9da2763b55972364b0Bd0B726E2F43",
      tokenAddress: "0x367cb081c591bf1e9d6e74bc81e5bedf971364ab",
      open : true
   }
];

import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import getReceipt from '../lib/getReceipt';
import { formatEther } from '@ethersproject/units';
import useToast from '../hooks/useToast';
import { PROPOSAL_LENGTH_IN_SECS } from '../constants';
// import {
//    addToast,
//    PendingTx,
//    SuccessfulTx,
//    PendingIPFS,
//    SuccessfulIPFS
// } from '../hooks/useToast';
import { isAddress } from '@ethersproject/address';
import { Contract } from '@ethersproject/contracts';
import ABI from '../constants/abi/nftdao.json';
import GOV_ABI from '../constants/abi/governor.json';
import DAO_ABI from '../constants/abi/nftdao.json';
import {
   defaultAbiCoder,
   FunctionFragment,
   Interface
} from '@ethersproject/abi';
import Select from 'react-select';

import { DateTime } from 'luxon';
import { Line } from 'rc-progress';

import Proposal from '../components/Proposal';

const encoder = defaultAbiCoder;

const Vote = () => {
   // const router = useRouter();

   //getting the contract of minty
   const { account, library } = useWeb3React();
   const addToast = useToast();

   //new for details
   const [daoAddress, setDaoAddress] = useState('');

   const [first_autocheck, set_first_autocheck] = useState(0);
   const [ischecked, setischecked] = useState(false);

   //nftdao
   const [govaddress, setgovaddress] = useState(
      '0x0000000000000000000000000000000000000000'
   );
   const [totalsupply_, settotalsupply] = useState(1);
   const [delegatee, setdelegatee] = useState(
      '0x0000000000000000000000000000000000000000'
   );
   const [new_delegate, set_new_delegate] = useState(account);

   //proposals
   // const [proposalcount, setproposalcount] = useState(0);
   const [proposals, setproposals] = useState([]);
   const [proposalsstatus, setproposalsstatus] = useState([]);
   const [proposaldescription, setproposaldescription] = useState([]);
   const [receipt, setreceipt] = useState([]);

   const [functionComponent, setFunctionComponent] = useState('HOME');
   const [proposal, setProposal] = useState(false);
   const [proposalStatus, setProposalStatus] = useState(false);
   const [proposalIndex, setProposalIndex] = useState(false);
   const [proposalReceipt, setProposalReceipt] = useState(false);
   const [advancedMode, setAdvancedMode] = useState(false);

   const [blocktime, setblocktime] = useState(0);
   const [voteend, setvoteend] = useState('');
   const [exec, setexec] = useState('');

   const [advancedvalue, setadvancedvalue] = useState(0);
   // const [sig, setsig] = useState('');
   const [calldata, setcalldata] = useState('');
   const [desc, setdesc] = useState('');

   //  const [switch_button, set_switch_button] = useState("TEMPLATE");
   //  const [switch_text, set_switch_text] = useState("Switch to Advanced")

   const sources = [DAO_ABI];
   const [contracts, setContracts] = useState([]);
   const [selection, setSelection] = useState({ address: null, name: null });
   const [metadata, setMetadata] = useState({});
   // const [executions, setExecutions] = useState([]);
   // const [targets, setTargets] = useState([]);
   const [selectfunctions, setselectsetfunctions] = useState([]);
   const [selectedfunction, setselectedfunction] = useState();

   const [fields, setFields] = useState([]);

   const gov_contract =
      isAddress(govaddress) && !!GOV_ABI && !!library
         ? new Contract(govaddress, GOV_ABI, library.getSigner(account))
         : undefined;

   // useEffect for setting clicked proposal data
   useEffect(() => {
      setProposalStatus(getProposalState(proposalsstatus[proposalIndex]));
      setProposalReceipt(receipt[proposalIndex]);
      // eslint-disable-next-line
   }, [proposalIndex]);

   // effect hook for updating data
   useEffect(() => {
      // update the ui elements
      async function updateUIStates() {
         console.log('updating');
         await checkDaoDetails();
         console.log('updating finisehd');
      }

      // schedule every 15 sec refresh
      const timer = setInterval(() => {
         if (first_autocheck === 0 && daoAddress !== '') {
            console.log('first auto check');
            console.log(first_autocheck);
            set_first_autocheck(1);
            updateUIStates();
         }
      }, 300);

      // clearing interval
      return () => clearInterval(timer);
      // eslint-disable-next-line
   }, [account, library, daoAddress, first_autocheck]);

   useEffect(() => {
      const sortAbis = () => {
         let instances = [];
         // let calls = [];
         let mapping = {};

         for (let contract in sources) {
            console.log(sources);

            let contractName = 'DAO Contract';
            let abi = sources[contract];

            instances.push({ value: daoAddress, label: contractName });
            mapping[contractName] = parseAbi2(abi);
         }

         /*
          Object.entries(state.indexes).map(o =>
              calls.push({
                label: `${o[1].address} [${o[1].symbol}]`,
                value: o[1].address,
              })
          )
    */
         setSelection(mapping['DAO Contract']);
         setContracts(instances);
         console.log(instances[0].label);
         setMetadata(mapping);
         //setTargets(calls)
      };

      sortAbis();
      // eslint-disable-next-line
   }, [daoAddress]);

   const parseAbi2 = abi => {
      const functionList = [];
      const functions = {};

      for (let funcAbi of abi) {
         let { name, inputs, type, stateMutability, payable } = funcAbi;

         if (!(type !== 'function' || stateMutability === 'view')) {
            const iface = new Interface(abi);
            const fragment = FunctionFragment.from(funcAbi);

            const signature = iface.getSighash(fragment);

            functionList.push({ label: name, value: signature });
            functions[signature] = {
               stateMutability,
               abi: funcAbi,
               signature,
               inputs,
               payable,
               name,
               type
            };
         }
      }

      return { functions, functionList };
   };

   const temp_abi = parseAbi2(DAO_ABI);
   console.log(temp_abi);

   function updatefunctions() {
      console.log(contracts);
      const myfunctions = metadata[contracts[0]['label']];
      console.log(myfunctions['functionList']);
      setselectsetfunctions(myfunctions['functionList']);
   }

   const onContractChange = value => {
      setSelection(value);
      updatefunctions();
   };

   const onFunctionChange = value => {
      console.log(value);
      const myfunctions = metadata[contracts[0]['label']];
      console.log(myfunctions);
      const detailed_function = myfunctions['functions'][value['value']];
      console.log(detailed_function);
      const inputs = detailed_function['inputs'];
      console.log('getting signature info');
      console.log(value);

      let i;
      let sig = '(';
      const values = [];
      for (i = 0; i < inputs.length; i++) {
         values.push({ name: inputs[i].name, type: inputs[i].type, value: 0 });
         sig += inputs[i].type + ',';
      }

      if (sig[sig.length - 1] === ',') {
         console.log('we have colon');
         sig = sig.slice(0, -1);
      }

      sig += ')';
      console.log(values);
      setFields(values);

      setselectedfunction(value['label'] + sig);
   };

   function handleChangeCalldata(event) {
      const values = event.target.value;
      setcalldata(values);
   }
   function handleChangeDescription(event) {
      const values = event.target.value;
      setdesc(values);
   }

   function handleChangeValue(i, event) {
      const values = [...fields];
      values[i].value = event.target.value;
      setFields(values);
   }

   function handleChangeValueAdvanced(event) {
      const values = event.target.value;
      setadvancedvalue(values);
   }

   async function proposeadvanced() {
      console.log('setting advanced to dao contract');
      console.log(daoAddress);

      console.log('values of advanced');
      console.log(advancedvalue);
      console.log(calldata);
      console.log(desc);

      const gov_contract =
         isAddress(govaddress) && !!GOV_ABI && !!library
            ? new Contract(govaddress, GOV_ABI, library.getSigner(account))
            : undefined;
      console.log(gov_contract);

      if (advancedvalue < 0) {
         addToast({
            body: 'Please enter a valid value for value (greater or equal 0)',
            type: 'error'
         });
         return;
      }

      try {
         const { hash } = await gov_contract.propose(
            [daoAddress],
            [advancedvalue],
            ['executeTransaction(address,uint256,string,bytes)'],
            [calldata],
            desc
         );
         await getReceipt(hash, library);
         checkDaoDetails();
      } catch (e) {
         addToast({ body: e.message, type: 'error' });
      }
   }

   async function proposetemplate() {
      try {
         console.log(selection);
         console.log(contracts);
         console.log(metadata);
         console.log(selectedfunction);
         console.log(daoAddress);

         let finaltarget = selection['value'];
         console.log(finaltarget);
         let finalvalue = 0;
         let finalsig = selectedfunction;

         console.log(fields);

         // loop through array and check if we have an array type to propose
         let i;

         for (i = 0; i < fields.length; i++) {
            if (
               fields[i].type === 'address[]' ||
               fields[i].type === 'uint256[]'
            ) {
               console.log(typeof fields[i].value);
               if (typeof fields[i].value !== 'object') {
                  let temp_array = fields[i].value.split(',');
                  fields[i].value = temp_array;
               }
            }
         }

         console.log(fields);

         let parameters = encoder.encode(
            fields.map(i => i.type),
            fields.map(i => i.value)
         );

         console.log('checking info');
         console.log(parameters);
         console.log(finalsig);
         console.log(finalvalue);
         console.log(desc);

         //calculate calldata

         const gov_contract =
            isAddress(govaddress) && !!GOV_ABI && !!library
               ? new Contract(govaddress, GOV_ABI, library.getSigner(account))
               : undefined;

         try {
            const { hash } = await gov_contract.propose(
               [finaltarget],
               [finalvalue],
               [finalsig],
               [parameters],
               desc
            );
            await getReceipt(hash, library);
            // setOpen();
            checkDaoDetails();
         } catch (e) {
            addToast({ body: e.message, type: 'error' });
         }
      } catch (e) {
         addToast({ body: e.message, type: 'error' });
      }
   }

   //format addresses in ui
   function format_address(address) {
      const new_address = address.substring(0, 5) + '...' + address.slice(-3);
      return new_address;
   }

   async function checkDaoDetails() {
      if (!account) {
         addToast({ body: 'Please Unlock Your Wallet', type: 'error' });
         return;
      }
      const { hide: hidePending } = addToast({
         body: 'Checking NFT Details',
         type: 'loading',
         hideAfter: 0
      });

      console.log(daoAddress);
      const dao_contract =
         isAddress(daoAddress) && !!ABI && !!library
            ? new Contract(daoAddress, ABI, library.getSigner(account))
            : undefined;
      console.log(dao_contract);

      try {
         const gov = await dao_contract._governor();
         const timelock = await dao_contract._timelock();

         console.log(gov);
         console.log(timelock);

         setgovaddress(gov);

         // const buyout = await dao_contract._buyoutPrice();
         const totalsupply = await dao_contract.totalSupply();
         // const sharessale = await dao_contract._sharesForSale();
         // const symbol = await dao_contract.symbol();
         const decimals = await dao_contract.decimals();
         const delegate = await dao_contract.delegates(account);

         setdelegatee(delegate);
         settotalsupply(totalsupply);

         console.log(totalsupply);
         console.log(decimals);

         //check for approval for nft transfer
         const gov_contract =
            isAddress(gov) && !!GOV_ABI && !!library
               ? new Contract(gov, GOV_ABI, library.getSigner(account))
               : undefined;
         console.log(gov_contract);
         const value = await gov_contract.proposalThreshold();
         console.log(value);

         console.log('check timelock');
         const timelocker = await gov_contract.timelock();
         console.log(timelocker);

         console.log('check timevote');
         const timelocke1r = await gov_contract.votingPeriod();
         console.log(timelocke1r);

         const nftxxx = await gov_contract.nft();
         console.log(nftxxx);
         setischecked(true);

         // get proposal
         const prop = await gov_contract.proposalCount();
         // setproposalcount(prop.toNumber());

         let prop_array = [];
         let prop_status_array = [];
         let receipt_array = [];

         let i;
         for (i = 1; i <= prop.toNumber(); i++) {
            let temp_prop = await gov_contract.proposals(i);
            let status = await gov_contract.state(i);
            const temp_receipt = await gov_contract.getReceipt(i, account);
            prop_array.push(temp_prop);
            prop_status_array.push(status);
            receipt_array.push(temp_receipt);
         }

         console.log(prop_array);
         console.log(receipt_array);
         setproposals(prop_array);
         setproposalsstatus(prop_status_array);
         setreceipt(receipt_array);
         parseAbi(GOV_ABI);
         console.log('status');
         console.log(prop_status_array);

         // get all descriptions for the events
         let filter = gov_contract.filters.ProposalCreated();
         const past_events = []
         /*
         const past_events = await library.getLogs({
            fromBlock: 0,
            toBlock: 'latest',
            address: gov_contract.address,
            topics: filter['topics']
         });

          */

         const eventParser = new Interface(GOV_ABI);
         let prop_desc_array = [];

         // eslint-disable-next-line
         past_events?.map(event => {
            console.log(event);
            const eventParsed = eventParser.parseLog(event).args;
            console.log(eventParsed);
            prop_desc_array.push(eventParsed.description);
         });

         setproposaldescription(prop_desc_array);

         hidePending();
         addToast({ body: 'Loading successful', type: 'success' });
      } catch (e) {
         console.log(e);
         setischecked(false);
         addToast({
            body:
               'The address provided does not resolve to a valid NFT DAO address',
            type: 'error'
         });
      }
   }

   useEffect(() => {
      async function fetchTimestamp() {
         if (proposal.startBlock) {
            try {
               const blockData = await library.getBlock(
                  proposal.startBlock.toNumber()
               );
               blockData && setblocktime(blockData.timestamp);

               let enddate = DateTime.fromSeconds(blockData.timestamp).plus({
                  seconds: PROPOSAL_LENGTH_IN_SECS
               });
               console.log(enddate.toLocaleString(DateTime.DATETIME_FULL));
               setvoteend(enddate.toLocaleString(DateTime.DATETIME_FULL));
            } catch (e) {
               console.log(e);
            }
         }

         if (proposal.eta) {
            try {
               console.log('getting eta');
               console.log(proposal.eta);
               console.log(proposal.eta.toNumber());
               let execdate = DateTime.fromSeconds(proposal.eta.toNumber());
               setexec(execdate.toLocaleString(DateTime.DATETIME_FULL));
            } catch (e) {
               console.log(e);
            }
         }
      }
      if (blocktime === 0) {
         fetchTimestamp();
      }
   }, [proposal, library, blocktime]);

   const parseAbi = abi => {
      const functionList = [];
      const functions = {};

      for (let funcAbi of abi) {
         let { type, stateMutability } = funcAbi;

         if (!(type !== 'function' || stateMutability === 'view')) {
            const iface = new Interface(abi);
            const fragment = FunctionFragment.from(funcAbi);

            const data = iface.getSighash(fragment);
            console.log(data);
         }
      }

      return { functions, functionList };
   };

   async function changedelegate() {
      const dao_contract =
         isAddress(daoAddress) && !!ABI && !!library
            ? new Contract(daoAddress, ABI, library.getSigner(account))
            : undefined;

      let delegate = new_delegate;

      // if (mode === 'self') {
      //    delegate = account;
      // }

      // console.log(delegate);

      try {
         const { hash } = await dao_contract.delegate(delegate);
         await getReceipt(hash, library);
         checkDaoDetails();
         set_new_delegate('');
      } catch (e) {
         addToast({ body: e.message, type: 'error' });
      }
   }

   function getProposalState(state_id) {
      if (state_id === 0) {
         return 'Pending';
      }
      if (state_id === 1) {
         return 'Active';
      }
      if (state_id === 2) {
         return 'Canceled';
      }
      if (state_id === 3) {
         return 'Defeated';
      }
      if (state_id === 4) {
         return 'Succeeded';
      }
      if (state_id === 5) {
         return 'Queued';
      }
      if (state_id === 6) {
         return 'Expired';
      }
      if (state_id === 7) {
         return 'Executed';
      }
   }

   // function handleChangeDelegate(event) {
   //    const values = event.target.value;
   //    set_new_delegate(values);
   // }

   // Component for managing what to display
   const getFunctionContent = () => {
      if (functionComponent === 'HOME') {
         return (
            <>
               <div className='vote__function-container--top'>
                  <h5 className='vote__function-container--header'>
                     PROPOSALS
                  </h5>
               </div>
               <div className='vote__function-container--center'>
                  {proposals.map((prop, i) => (
                     <Proposal
                        proposal={prop}
                        proposalStatus={getProposalState(proposalsstatus[i])}
                        proposalDescription={proposaldescription[i]}
                        setProposalIndex={setProposalIndex}
                        proposals={proposals}
                        setProposal={setProposal}
                        setFunctionComponent={setFunctionComponent}
                        index={i}
                        key={i}
                     />
                  ))}
               </div>
            </>
         );
      }
      if (functionComponent === 'NEW PROPOSAL') {
         return (
            <>
               <div className='vote__function-container--top'>
                  <h5 className='vote__function-container--header'>
                     CREATE PROPOSAL
                  </h5>
                  <button
                     className='dao__back-button vote__function-container--back-button'
                     onClick={() => setFunctionComponent('HOME')}
                  >
                     Go back
                  </button>
               </div>
               <div className='vote__function-container--center centered flex-centered'>
                  <button
                     className='dao__back-button vote__function-container--back-button flex-end no-padding'
                     onClick={() => setAdvancedMode(!advancedMode)}
                  >
                     {advancedMode ? 'Template mode' : 'Advanced mode'}
                  </button>
                  {!advancedMode ? (
                     <>
                        <h5 className='vote__function-container--label'>
                           Target:
                        </h5>
                        <Select
                           className='react-select'
                           label='Contract'
                           options={contracts}
                           onChange={onContractChange}
                        />
                        {/* <select
                           onClick={onContractChange}
                           onChange={onContractChange}
                           className='form__input vote__form--input'
                        >
                           {contracts.map((contract, i) => (
                              <option key={i} value={contract.value}>
                                 {contract.label}
                              </option>
                           ))}
                        </select>
                        {console.log(contracts)} */}
                        <h5 className='vote__function-container--label'>
                           Function:
                        </h5>
                        <Select
                           className='react-select'
                           label='Function'
                           options={selectfunctions}
                           onChange={onFunctionChange}
                        />
                        {/* <select
                           onClick={e => {
                              setSelectedFunction(e.target.value);
                              onFunctionChange(e.target.value);
                           }}
                           onChange={e => {
                              setSelectedFunction(e.target.value);
                              onFunctionChange(e.target.value);
                           }}
                           className='form__input vote__form--input'
                        >
                           {selectfunctions.map((contract, i) => (
                              <option key={i} value={contract.value}>
                                 {contract.label}
                              </option>
                           ))}
                        </select> */}
                        {fields.map((field, idx) => {
                           return (
                              <div
                                 className='vote__function-container--field-div'
                                 key={`${field}-${idx}`}
                              >
                                 <h5 className='vote__function-container--label'>
                                    {`${field.name} (${field.type}):`}
                                 </h5>
                                 <input
                                    className='form__input vote__form--input'
                                    onChange={e => handleChangeValue(idx, e)}
                                 />
                              </div>
                           );
                        })}
                        <h5 className='vote__function-container--label'>
                           Description:
                        </h5>
                        <textarea
                           className='form__input vote__form--input'
                           onChange={e => handleChangeDescription(e)}
                        />
                        <h5 className='vote__function-container--label dao__details-container--label dao__details-container--label-grey'>
                           Info: For Array values please seperate the values
                           with a comma ( , )
                        </h5>
                        <button
                           className='dao__details-container--button vote__button--vote'
                           onClick={proposetemplate}
                        >
                           PROPOSE
                        </button>
                     </>
                  ) : (
                     <>
                        <h5 className='vote__function-container--label'>
                           Value:
                        </h5>
                        <input
                           className='form__input vote__form--input'
                           onChange={e => handleChangeValueAdvanced(e)}
                        />
                        <h5 className='vote__function-container--label'>
                           Calldata:
                        </h5>
                        <textarea
                           className='form__input vote__form--input'
                           onChange={e => handleChangeCalldata(e)}
                        />
                        <h5 className='vote__function-container--label'>
                           Description:
                        </h5>
                        <textarea
                           className='form__input vote__form--input'
                           onChange={e => handleChangeDescription(e)}
                        />
                        <button
                           className='dao__details-container--button vote__button--vote'
                           onClick={proposeadvanced}
                        >
                           PROPOSE
                        </button>
                     </>
                  )}
               </div>
            </>
         );
      }
      if (functionComponent === 'SET DELEGATOR') {
         return (
            <>
               <div className='vote__function-container--top'>
                  <h5 className='vote__function-container--header'>
                     CHANGE DELEGATOR
                  </h5>
                  <button
                     className='dao__back-button vote__function-container--back-button'
                     onClick={() => setFunctionComponent('HOME')}
                  >
                     Go back
                  </button>
               </div>
               <div className='vote__function-container--center'>
                  <form className='vote__form vote__form--delegator'>
                     <h3 className='form__label'>Enter Delegator Address:</h3>
                     <input
                        type='text'
                        required
                        className='form__input vote__form--input'
                        onChange={e => set_new_delegate(e.target.value)}
                        value={new_delegate}
                     />
                     <button
                        className='form__button vote__form--button'
                        onClick={e => {
                           e.preventDefault();
                           changedelegate();
                        }}
                     >
                        CHANGE
                     </button>
                  </form>
               </div>
            </>
         );
      }
      if (functionComponent === 'PROPOSAL') {
         return (
            <>
               <div className='vote__function-container--top'>
                  <h5 className='vote__function-container--header'>
                     PROPOSAL {proposal.id.toNumber()}
                  </h5>
                  <button
                     className='dao__back-button vote__function-container--back-button'
                     onClick={() => setFunctionComponent('HOME')}
                  >
                     Go back
                  </button>
               </div>
               <div className='vote__function-container--center centered'>
                  <div className='dao__details-container--flex-container'>
                     {proposalStatus === 'Pending' ||
                     proposalStatus === 'Active' ? (
                        <h5 className='vote__function-container--label'>
                           Voting ends on {voteend}
                        </h5>
                     ) : (
                        <h5 className='vote__function-container--label'>
                           Executable on {exec}
                        </h5>
                     )}
                     <h5 className='vote__function-container--label vote__function-container--status'>
                        {getProposalState(proposalsstatus[proposalIndex])}
                     </h5>
                  </div>
                  <div className='dao__details-container--flex-container'>
                     <div className='vote__function-container--flex-column'>
                        <h5 className='vote__function-container--label flex-start'>
                           For: {formatEther(proposal.forVotes)}
                        </h5>
                        <Line
                           percent={
                              (formatEther(proposal.forVotes) /
                                 formatEther(totalsupply_)) *
                              100
                           }
                           strokeWidth='4'
                           trailWidth='4'
                           strokeColor={[
                              '#08c4d1',
                              {
                                 '100%': '#ffffff',
                                 '0%': '#08c4d1'
                              }
                           ]}
                           strokeLinecap='round'
                        />
                     </div>
                     <div className='vote__function-container--flex-column'>
                        <h5 className='vote__function-container--label flex-end'>
                           Against: {formatEther(proposal.againstVotes)}
                        </h5>
                        <Line
                           percent={
                              (formatEther(proposal.againstVotes) /
                                 formatEther(totalsupply_)) *
                              100
                           }
                           strokeWidth='4'
                           trailWidth='4'
                           strokeColor={[
                              'red',
                              {
                                 '100%': '#ffffff',
                                 '0%': '#00ffad'
                              }
                           ]}
                           strokeLinecap='round'
                        />
                     </div>
                  </div>
                  {console.log(proposalStatus)}
                  {proposalReceipt ? (
                     proposalStatus ? (
                        proposalStatus !== 'Canceled' &&
                        proposalStatus !== 'Executed' &&
                        proposalStatus !== 'Queued' ? (
                           proposalReceipt.hasVoted === false ? (
                              <div className='dao__details-container--flex-container'>
                                 <button
                                    className='dao__details-container--button vote__button--vote'
                                    onClick={votefor}
                                 >
                                    Vote For
                                 </button>
                                 <button
                                    className='dao__details-container--button vote__button--vote'
                                    onClick={voteagainst}
                                 >
                                    Vote Against
                                 </button>
                              </div>
                           ) : (
                              <div className='dao__details-container--flex-container'>
                                 {' '}
                                 <h5 className='vote__function-container--label'>
                                    You have voted{' '}
                                    {proposalReceipt.support === true
                                       ? 'For'
                                       : 'Against'}
                                    .
                                 </h5>
                                 <h5 className='vote__function-container--label'>
                                    Your Votes:{' '}
                                    {formatEther(proposalReceipt.votes)}
                                 </h5>
                              </div>
                           )
                        ) : (
                           ''
                        )
                     ) : (
                        ''
                     )
                  ) : (
                     ''
                  )}
                  <h5 className='vote__function-container--label'>
                     {`${proposaldescription[proposalIndex]}`}
                  </h5>
                  <h5 className='vote__function-container--label dao__details-container--label dao__details-container--label-grey'>
                     Proposer: {proposal.proposer}
                  </h5>
                  {proposal ? (
                     proposal ? (
                        proposalStatus === 'Succeeded' ? (
                           <button
                              className='dao__details-container--button vote__button'
                              onClick={queueProposal}
                           >
                              QUEUE
                           </button>
                        ) : proposalStatus === 'Queued' ? (
                           <button
                              className='dao__details-container--button vote__button'
                              onClick={executeProposal}
                           >
                              EXECUTE
                           </button>
                        ) : (
                           ''
                        )
                     ) : (
                        ''
                     )
                  ) : (
                     ''
                  )}
                  {proposal ? (
                     proposalStatus !== 'Canceled' ? (
                        proposalStatus !== 'Executed' ? (
                           <button
                              className='dao__details-container--button vote__button'
                              onClick={cancelProposal}
                           >
                              CANCEL
                           </button>
                        ) : (
                           ''
                        )
                     ) : (
                        ''
                     )
                  ) : (
                     ''
                  )}
               </div>
            </>
         );
      }
   };

   async function votefor() {
      try {
         const { hash } = await gov_contract.castVote(proposal.id, true);
         await getReceipt(hash, library);
         checkDaoDetails();
      } catch (e) {
         addToast({ body: e.message, type: 'error' });
      }
   }

   async function voteagainst() {
      try {
         const { hash } = await gov_contract.castVote(proposal.id, false);
         await getReceipt(hash, library);
         checkDaoDetails();
      } catch (e) {
         addToast({ body: e.message, type: 'error' });
      }
   }

   async function queueProposal() {
      try {
         const { hash } = await gov_contract.queue(proposal.id);
         await getReceipt(hash, library);
         checkDaoDetails();
      } catch (e) {
         addToast({ body: e.message, type: 'error' });
      }
   }

   async function executeProposal() {
      try {
         const { hash } = await gov_contract.execute(proposal.id);
         await getReceipt(hash, library);
         checkDaoDetails();
      } catch (e) {
         addToast({ body: e.message, type: 'error' });
      }
   }

   async function cancelProposal() {
      try {
         const { hash } = await gov_contract.cancel(proposal.id);
         await getReceipt(hash, library);
         checkDaoDetails();
      } catch (e) {
         addToast({ body: e.message, type: 'error' });
      }
   }

   return (
      <>
         <div className='page'>
            <div className='page__container'>
               <h3 className='page__header'>VOTE</h3>
               {!ischecked ? (
                  <div className='page__flex-container card-shadow'>
                     <div className='vote__container'>
                        <div className='vote__container--inline-div'>
                           <h4 className='vote__container--header'>
                              Vote and Propose
                           </h4>
                        </div>

                        <form className='vote__form'>
                           <h3 className='form__label'>
                              Enter NFT DAO Token Address:
                           </h3>
                           <input
                              type='text'
                              required
                              className='form__input vote__form--input'
                              onChange={e => setDaoAddress(e.target.value)}
                              value={daoAddress}
                           />
                           <button
                              className='form__button vote__form--button'
                              onClick={e => {
                                 e.preventDefault();
                                 checkDaoDetails();
                              }}
                           >
                              GET DETAILS
                           </button>
                        </form>
                     </div>
                  </div>
               ) : (
                  <div className='dao__wrapper'>
                     <div className='dao__details-wrapper'>
                        <button
                           className='dao__back-button'
                           onClick={() => setischecked(false)}
                        >
                           Change DAO address
                        </button>
                        <div className='dao__details-container card-shadow'>
                           <div className='dao__details-container--top'>
                              <h4 className='dao__details-container--header'>
                                 {`${daoAddress.slice(
                                    0,
                                    12
                                 )}...${daoAddress.slice(29, 41)}`}
                              </h4>
                           </div>
                           <div className='dao__details-container--secondary'>
                              <div className='dao__details-container--flex-container'>
                                 <h5 className='dao__details-container--label'>
                                    Current Delegator:
                                 </h5>
                                 <h5 className='dao__details-container--label dao__details-container--label-grey'>
                                    {format_address(delegatee)}
                                 </h5>
                              </div>
                              <h5 className='dao__details-container--label'>
                                 DAO Functions:
                              </h5>
                              <button
                                 className='dao__details-container--button vote__button'
                                 onClick={() =>
                                    setFunctionComponent('NEW PROPOSAL')
                                 }
                              >
                                 PROPOSAL
                              </button>
                              <button
                                 className='dao__details-container--button vote__button'
                                 onClick={() =>
                                    setFunctionComponent('SET DELEGATOR')
                                 }
                              >
                                 CHANGE DELEGATOR
                              </button>
                           </div>
                        </div>
                     </div>
                     <div className='vote__function-container card-shadow'>
                        {getFunctionContent()}
                     </div>
                  </div>
               )}
            </div>
         </div>
      </>
   );
};

export default Vote;

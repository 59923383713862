import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { formatEther } from '@ethersproject/units';
import { useWeb3React } from '@web3-react/core';
import getReceipt from '../lib/getReceipt';
import { addToast } from '../hooks/useToast';
import { BigNumber } from '@ethersproject/bignumber';
import { isAddress } from '@ethersproject/address';
import { Contract } from '@ethersproject/contracts';
import ABI from '../constants/abi/nftdao.json';
import ERC21 from '../constants/abi/erc721.json';

import {JsonRpcProvider} from "@ethersproject/providers";
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const Dao = () => {
   const library_infura = new JsonRpcProvider("https://rpc-mainnet.maticvigil.com/v1/fcb5333fa036d7a20e56ebdcfc2d2ba4fd9f5cd8\n")

   //getting the contract of minty
   const { account, library } = useWeb3React();

   //new for details
   const [daoAddress, setDaoAddress] = useState('');

   const [first_autocheck, set_first_autocheck] = useState(0);
   const [check_query, set_check_query] = useState(0);
   const [isinited, setisinited] = useState(false);

   //nftdao
   const [buyoutprice, setbuyoutprice] = useState(0);
   const [tokentotalsupply, settokentotalsupply] = useState(0);
   const [tokensymbol, settokensymbol] = useState('');
   const [tokenname, settokenname] = useState('');
   const [sharesforsale, setsharesforsale] = useState(0);

   const [contractbalance, setcontractbalance] = useState(0);
   const [userbalance, setuserbalance] = useState(0);

   const [buyAmount, setbuyAmount] = useState(0);

   const [nftArray, setNftArray] = useState([]);
   const [nftselect, setnftselect] = useState({});

   const [buyeraddress, setbuyeraddress] = useState(
      '0x0000000000000000000000000000000000000000'
   );
   const [userbuyout, setuserbuyout] = useState('0');
   const [towithdraw, settowithdraw] = useState([]);

   const location = useLocation();

   // Start from url parameter
   useEffect(() => {
      const address = location.search.split('?address=')[1];
      if (address) {
         setDaoAddress(address);
         set_check_query(1);
      }
   }, [location]);

   // effect hook for updating data
   useEffect(() => {
      // update the ui elements
      async function updateUIStates() {
         console.log('updating');
         await checkDaoDetails();
         console.log('updating finisehd');
      }

      // schedule every 15 sec refresh
      const timer = setInterval(() => {
         if (first_autocheck === 0 && daoAddress !== '' && check_query === 1) {
            console.log('first auto check');
            console.log(first_autocheck);
            set_first_autocheck(1);
            updateUIStates();
         }
      }, 300);

      // clearing interval
      return () => clearInterval(timer);
      // eslint-disable-next-line
   }, [account, library, daoAddress, first_autocheck, check_query]);

   //format addresses in ui
   // function format_address(address) {
   //    const new_address = address.substring(0, 10) + '...' + address.slice(-8);
   //    return new_address;
   // }

   // function formatidetherscan(address) {
   //    let splitter = address.split('.');
   //    let towork = splitter[0];

   //    return towork;
   // }

   function formatId(address) {
      let splitter = address.split('.');
      // let towork = splitter[0];

      if (splitter[0].length > 10) {
         return splitter[0].substring(0, 10) + '...';
      } else {
         return splitter[0];
      }
   }

   async function checkDaoDetails() {
      console.log(daoAddress);
      const dao_contract =
         isAddress(daoAddress) && !!ABI && !!library_infura
            ? new Contract(daoAddress, ABI, library_infura)
            : undefined;
      console.log(dao_contract);

      const { hide: hidePending } = addToast({
         body: 'Checking NFT Details',
         type: 'loading',
         hideAfter: 0
      });

      try {
         const nftarray = await dao_contract._raisedNftArray(0);
         console.log(nftarray);
         // setischecked(true);

         const gov = await dao_contract._governor();

         let init = true;
         if (gov === '0x0000000000000000000000000000000000000000') {
            init = false;
         }

         setisinited(init);

         const buyout = await dao_contract._buyoutPrice();
         const totalsupply = await dao_contract.totalSupply();
         const sharessale = await dao_contract._sharesForSale();
         const symbol = await dao_contract.symbol();
         const name = await dao_contract.name();
         const buyeradd = await dao_contract._buyoutAddress();

         console.log(sharessale);

         setbuyoutprice(buyout);
         settokentotalsupply(totalsupply);
         settokensymbol(symbol);
         settokenname(name);
         setsharesforsale(sharessale);
         setbuyeraddress(buyeradd);

         const balance = await library_infura.getBalance(daoAddress);
         setcontractbalance(balance);

         if (account) {
            const userbalance = await dao_contract.balanceOf(account);
            setuserbalance(userbalance);
            const userbuyoutprice = await dao_contract.getBuyoutPriceWithDiscount(
               account
            );
            setuserbuyout(userbuyoutprice);
         }

         // get nft array members for sale
         const nftarraycount = await dao_contract._nftCount();
         let i = 0;
         let temp_array = [];
         let nft_temp_select = [];

         for (i; i < nftarraycount; i++) {
            let temp_array_member = await dao_contract._raisedNftArray(i);
            temp_array.push(temp_array_member);

            // get details of nft
            let erc721_contract =
               isAddress(temp_array_member.nftaddress) &&
               !!ERC21 &&
               !!library_infura
                  ? new Contract(
                       temp_array_member.nftaddress,
                       ERC21,
                       library_infura
                    )
                  : undefined;

            console.log(erc721_contract);

            let tname = '';
            let tsymbol = '';

            try {
               tname = await erc721_contract.name();
            } catch {}
            try {
               tsymbol = await erc721_contract.symbol();
            } catch {}

            let turl;
            try {
               turl = await erc721_contract.tokenURI(temp_array_member.tokenid);
            } catch {}

            console.log(turl);

            let res;
            let json;

            try {
               res = await fetch(turl);
               json = await res.json();
            } catch {}

            console.log(json);
            let imager = '';

            try {
               imager = json.image;
            } catch {}

            const ipfsPrefix = 'https://dweb.link/ipfs/';

            if (imager && imager.startsWith('ipfs:')) {
               console.log(json.image);
               imager = 'https://gateway.pinata.cloud/' + imager.substring(7);
            } else {
               try {
                  imager = json.result.data.big_image;
               } catch {}
            }

            if (imager && !imager.startsWith('http')) {
               imager = ipfsPrefix + imager;
            }

            console.log(imager);

            let typer = 'image';
            try {
               let response = await fetch(imager);
               let data = await response.blob();
               console.log(data.type);
               if (data.type.includes('video')) {
                  typer = 'video';
               } else {
                  typer = 'image';
               }
            } catch (e) {}

            console.log(temp_array_member.tokenid);

            let temp_json = {
               address: temp_array_member.nftaddress,
               tokenid: temp_array_member.tokenid,
               price: temp_array_member.price,
               arraynumber: i,
               sale: temp_array_member.forSale,
               name: tname,
               symbol: tsymbol,
               url: turl,
               image: imager,
               type: typer
            };
            nft_temp_select.push(temp_json);
         }

         setNftArray(nft_temp_select);
         console.log(nft_temp_select);

         setnftselect(nft_temp_select[0]);

         hidePending();
         addToast({ body: 'Loading successful', type: 'success' });
      } catch (e) {
         console.log(e);
         // setischecked(false);
         hidePending();
         addToast({
            body:
               'The address provided does not resolve to a valid NFT DAO address',
            type: 'error'
         });
      }
   }

   async function buyShares() {
      if (!account) {
         addToast({ body: 'Please Unlock Your Wallet', type: 'error' });
      } else {
         const amount = buyAmount;
         const dao_contract =
            isAddress(daoAddress) && !!ABI && !!library
               ? new Contract(daoAddress, ABI, library.getSigner(account))
               : undefined;

         if (amount < 1) {
            addToast({
               body: 'Please enter a valid buying quantity (1+)',
               type: 'error'
            });
         } else {
            const round_amount = Math.floor(amount);
            let totalamount = BigNumber.from(0);

            let i;

            console.log(buyoutprice);
            console.log(tokentotalsupply);

            for (i = 0; i < round_amount; i++) {
               totalamount = totalamount.add(buyoutprice.div(tokentotalsupply));
            }
            console.log(round_amount);
            console.log(totalamount);

            const trans_obj = {
               // Required unless deploying a contract (in which case omit)
               //gasLimit: 300000,        // the maximum gas this transaction may spend
               value: totalamount // the amount (in wei) this transaction is sending
            };

            try {
               const { hash } = await dao_contract.buyshares(
                  round_amount,
                  trans_obj
               );
               await getReceipt(hash, library);
               await checkDaoDetails();
            } catch (e) {
               addToast({ body: e.message, type: 'error' });
            }
         }
      }
   }

   async function burnforeth() {
      if (!account) {
         addToast({ body: 'Please Unlock Your Wallet', type: 'error' });
      } else {
         const dao_contract =
            isAddress(daoAddress) && !!ABI && !!library
               ? new Contract(daoAddress, ABI, library.getSigner(account))
               : undefined;
         try {
            const { hash } = await dao_contract.burnForETH();
            await getReceipt(hash, library);
            await checkDaoDetails();
         } catch (e) {
            addToast({ body: e.message, type: 'error' });
         }
      }
   }

   async function withdrawNfts() {
      if (!account) {
         addToast({ body: 'Please Unlock Your Wallet', type: 'error' });
      } else {
         const dao_contract =
            isAddress(daoAddress) && !!ABI && !!library
               ? new Contract(daoAddress, ABI, library.getSigner(account))
               : undefined;
         try {
            let withdrawvaluestring = towithdraw;
            let withdrawvaluearray = withdrawvaluestring.split(',');

            let pusharay = [];

            let i;
            for (i = 0; i < withdrawvaluearray.length; i++) {
               pusharay.push(parseInt(withdrawvaluearray[i]));
            }

            console.log(pusharay);

            const { hash } = await dao_contract.buyoutWithdraw(pusharay);
            await getReceipt(hash, library);
            await checkDaoDetails();
         } catch (e) {
            addToast({ body: e.message, type: 'error' });
         }
      }
   }

   async function buyout() {
      if (!account) {
         addToast({ body: 'Please Unlock Your Wallet', type: 'error' });
      } else {
         const dao_contract =
            isAddress(daoAddress) && !!ABI && !!library
               ? new Contract(daoAddress, ABI, library.getSigner(account))
               : undefined;
         try {
            const trans_obj = {
               // Required unless deploying a contract (in which case omit)
               //gasLimit: 500000,        // the maximum gas this transaction may spend
               value: userbuyout // the amount (in wei) this transaction is sending
            };

            let buyoutp = buyoutprice;
            let totalminus = tokentotalsupply.sub(userbalance);
            let temp = totalminus
               .mul('1000000000000000000000000')
               .div(tokentotalsupply);
            let temp2 = temp.div('1000000000000000000000000');

            let buyoutmulmul = buyoutprice.mul(totalminus);

            let diver = buyoutmulmul.div('1000000000000000000000000');

            console.log(buyoutp);
            console.log(totalminus);
            console.log(temp);
            console.log(temp2);
            console.log(buyoutmulmul);
            console.log(diver);
            console.log(
               buyoutprice
                  .mul(tokentotalsupply.sub(userbalance))
                  .mul('1000000000000000000')
                  .div(tokentotalsupply)
                  .div('1000000000000000000')
            );
            console.log(
               buyoutprice
                  .mul(tokentotalsupply.sub(userbalance))
                  .div(tokentotalsupply)
            );
            console.log(
               buyoutprice.mul(
                  tokentotalsupply.sub(userbalance).div(tokentotalsupply)
               )
            );

            const { hash } = await dao_contract.buyout(trans_obj);
            await getReceipt(hash, library);
            await checkDaoDetails();
         } catch (e) {
            addToast({ body: e.message, type: 'error' });
         }
      }
   }

   async function buyoutSingleNft(arrayId, buyoutprice) {
      if (!account) {
         addToast({ body: 'Please Unlock Your Wallet', type: 'error' });
      } else {
         const dao_contract =
            isAddress(daoAddress) && !!ABI && !!library
               ? new Contract(daoAddress, ABI, library.getSigner(account))
               : undefined;
         try {
            const trans_obj = {
               value: buyoutprice
            };

            console.log(arrayId);
            console.log(buyoutprice);

            const { hash } = await dao_contract.buySingleNft(
               arrayId,
               trans_obj
            );
            await getReceipt(hash, library);
            await checkDaoDetails();
         } catch (e) {
            addToast({ body: e.message, type: 'error' });
         }
      }
   }

   function handleChangeBuyAmount(event) {
      const values = event.target.value;
      setbuyAmount(values);
   }

   function handleChangetowithdraw(event) {
      const values = event.target.value;
      settowithdraw(values);
   }

   function oncarouselchange(event) {
      const values = parseInt(event);
      console.log(values);

      let tempnft = nftArray[values];
      console.log(tempnft);

      setnftselect(tempnft);
   }

   function copylink() {
      const el = document.createElement('textarea');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      el.setAttribute('readonly', '');
      const minty_uri = 'https://polygon.alchemydao.com/dao';
      const stringer = minty_uri + '?address=' + daoAddress;
      el.value = stringer;
      document.body.appendChild(el);
      //el.style = {position: 'absolute', left: '-9999px',height: '0', opacity: '.01'};
      //document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      addToast({ body: 'Copied Link To Your Clipboard', type: 'success' });
   }

   return (
      <div className='page'>
         <div className='page__container'>
            <h3 className='page__header'>DAO</h3>
            {!isinited ? (
               <div className='page__flex-container card-shadow'>
                  <div className='dao__container'>
                     <div className='dao__container--inline-div'>
                        <h4 className='dao__container--header'>
                           Enter DAO Token Address
                        </h4>
                     </div>

                     <form className='dao__form'>
                        <h3 className='form__label'>Token Address:</h3>
                        <input
                           type='text'
                           required
                           className='form__input dao__form--input'
                           onChange={e => setDaoAddress(e.target.value)}
                           value={daoAddress || ''}
                        />
                        <button
                           className='form__button dao__form--button'
                           onClick={e => {
                              e.preventDefault();
                              checkDaoDetails();
                           }}
                        >
                           GET DAO DETAILS
                        </button>
                     </form>
                  </div>
               </div>
            ) : (
               <div className='dao__wrapper'>
                  <div className='dao__details-wrapper'>
                     <button
                        className='dao__back-button'
                        onClick={() => setisinited(false)}
                     >
                        Change DAO Address
                     </button>
                     <div className='dao__details-container card-shadow'>
                        <div className='dao__details-container--top'>
                           <h4 className='dao__details-container--header'>
                              {`${tokenname || ' '} (${tokensymbol || ' '})`}
                           </h4>
                           <button
                              className='dao__details-container--button dao__details-container--button-copy'
                              onClick={() => copylink()}
                           >
                              COPY LINK
                           </button>
                        </div>
                        <div className='dao__details-container--secondary'>
                           {account ? (
                              <div className='dao__details-container--flex-container'>
                                 <h5 className='dao__details-container--label'>
                                    Your shares:
                                 </h5>
                                 <h5 className='dao__details-container--label'>
                                    {formatEther(userbalance)}
                                 </h5>
                              </div>
                           ) : (
                              <h5 className='dao__details-container--label dao__details-container--label-grey'>
                                 Connect wallet to see shares
                              </h5>
                           )}
                           {formatEther(sharesforsale) !== '0.0' ? (
                              <>
                                 <h5 className='dao__details-container--label'>
                                    Buy Shares
                                 </h5>

                                 <input
                                    required
                                    className='form__input'
                                    type='number'
                                    min='0'
                                    pattern='[0-9]{10}'
                                    step='1'
                                    value={buyAmount}
                                    onChange={e => handleChangeBuyAmount(e)}
                                 />

                                 <h5 className='dao__details-container--label'>
                                    Cost{' '}
                                    {(
                                       (buyAmount * formatEther(buyoutprice)) /
                                       formatEther(tokentotalsupply)
                                    ).toFixed(5)}
                                 </h5>

                                 <button
                                    className='dao__details-container--button dao__nft-container--center--button'
                                    type='button'
                                    onClick={() => buyShares()}
                                 >
                                    BUY {buyAmount}
                                 </button>
                              </>
                           ) : (
                              <div className='dao__details-container--flex-container'>
                                 <h5 className='dao__details-container--label'>
                                    Fund raising:
                                 </h5>
                                 <h5 className='dao__details-container--label'>
                                    Not now
                                 </h5>
                              </div>
                           )}
                           <div className='dao__details-container--flex-container'>
                              <h5 className='dao__details-container--label'>
                                 Burn shares for:
                              </h5>
                              <h5 className='dao__details-container--label'>
                                 {formatEther(userbalance) === '0.0'
                                    ? formatEther(userbalance)
                                    : formatEther(
                                         contractbalance
                                            .mul(tokentotalsupply)
                                            .div(userbalance)
                                      )}{' '}
                                 MATIC
                              </h5>
                           </div>
                           <button
                              className='dao__details-container--button'
                              onClick={() => burnforeth()}
                           >
                              BURN
                           </button>

                           {(buyeraddress ===
                              '0x0000000000000000000000000000000000000000') &
                           !account ? (
                              <>
                                 <div className='dao__details-container--flex-container'>
                                    <h5 className='dao__details-container--label'>
                                       Buyout the DAO Contract for:{' '}
                                    </h5>
                                    <h5 className='dao__details-container--label'>
                                       {formatEther(buyoutprice)} MATIC
                                    </h5>
                                 </div>
                                 <button
                                    className='dao__details-container--button'
                                    onClick={() => buyout()}
                                 >
                                    BUYOUT
                                 </button>
                              </>
                           ) : (
                              ''
                           )}

                           {buyeraddress ===
                              '0x0000000000000000000000000000000000000000' &&
                           account ? (
                              <>
                                 <>
                                    <div className='dao__details-container--flex-container'>
                                       <h5 className='dao__details-container--label'>
                                          Buyout the DAO Contract for:{' '}
                                       </h5>
                                       <h5 className='dao__details-container--label'>
                                          {formatEther(buyoutprice)} MATIC
                                       </h5>
                                    </div>
                                    <button
                                       className='dao__details-container--button'
                                       onClick={() => buyout()}
                                    >
                                       BUYOUT
                                    </button>
                                 </>
                              </>
                           ) : (
                              ''
                           )}

                           {buyeraddress === account && (
                              <>
                                 <h5 className='dao__details-container--label'>
                                    Withdraw Nfts from the DAO
                                    <br />
                                    <span className='dao__details-container--label-grey'>
                                       Enter the IDs comma seperated (In case
                                       you only have one NFT in the DAO enter 0)
                                    </span>
                                 </h5>
                                 <input
                                    required
                                    className='form__input'
                                    type='text'
                                    value={towithdraw}
                                    onChange={e => handleChangetowithdraw(e)}
                                 />

                                 <button
                                    className='dao__details-container--button dao__nft-container--center--button'
                                    type='button'
                                    onClick={() => withdrawNfts()}
                                 >
                                    WITHDRAW NFT'S
                                 </button>
                              </>
                           )}
                        </div>
                     </div>
                  </div>
                  <div className='dao__nft-container card-shadow'>
                     <div className='dao__nft-container--top'>
                        <Carousel onChange={e => oncarouselchange(e)}>
                           {nftArray.map((data, i) => (
                              <div key={i} className='dao__carousel'>
                                 {data.image ? (
                                    data.type === 'image' ? (
                                       <img src={data.image} alt='NFT' />
                                    ) : (
                                       <video
                                          autoPlay
                                          loop
                                          muted
                                          inline
                                          controls
                                          className='video-container'
                                       >
                                          <source
                                             src={data.image}
                                             type='video/mp4'
                                          />
                                          Your browser does not support the
                                          video tag.
                                       </video>
                                    )
                                 ) : (
                                    <img
                                       src='alchemy_square.png'
                                       alt='Alchemy Logo'
                                    />
                                 )}
                                 <p className='legend'>
                                    {data.name} ({data.symbol})
                                 </p>
                              </div>
                           ))}
                        </Carousel>
                     </div>
                     <div className='dao__nft-container--center'>
                        <h4 className='dao__nft-container--center--text'>
                           {`Name: ${nftselect.name || ''}`}
                        </h4>

                        <h4 className='dao__nft-container--center--text'>
                           {`Symbol: ${nftselect.symbol || ''}`}
                        </h4>
                        <h4 className='dao__nft-container--center--text'>
                           {`ID: ${
                              (nftselect.tokenid &&
                                 formatId(nftselect.tokenid.toString()).slice(
                                    0,
                                    7
                                 )) ||
                              ''
                           }`}
                        </h4>
                        <h4 className='dao__nft-container--center--text'>
                           <a
                              href={`https://explorer-mainnet.maticvigil.com/token/${nftselect.address}`}
                           >{`${nftselect.address || ''}`}</a>
                        </h4>
                        <h4 className='dao__nft-container--center--text'>
                           <a href={nftselect.url}>{nftselect.url || ''}</a>
                        </h4>

                        {nftselect.sale ? (
                           <>
                              <h4 className='dao__nft-container--center--text'>
                                 For sale
                              </h4>
                              <h4 className='dao__nft-container--center--text'>
                                 Price: {formatEther(nftselect.price) || ''} MATIC
                              </h4>
                              <div className='dao__nft-container--center--flex-div'>
                                 <button
                                    className='dao__details-container--button dao__nft-container--center--button'
                                    type='button'
                                    onClick={() =>
                                       buyoutSingleNft(
                                          nftselect.arraynumber,
                                          nftselect.price
                                       )
                                    }
                                 >
                                    Buy {nftselect.name || ''}
                                 </button>
                              </div>
                           </>
                        ) : (
                           <h4 className='dao__nft-container--center--text'>
                              Not for sale
                           </h4>
                        )}
                     </div>
                  </div>
               </div>
            )}
         </div>
      </div>
   );
};

export default Dao;

import React from 'react';
import { useWeb3React } from '@web3-react/core';

import { connectorsByName } from '../lib/connectors';

const ConnectModal = ({ setConnectModal }) => {
   const { deactivate, activate, account } = useWeb3React();

   const handleConnect = async name => {
      // setActivatingConnector(currentConnector);
      await activate(name);
      setConnectModal(false);
   };

   const handleNetwork = e => {
      const network = e.target.value;
      if (network === 'mainnet') {
         window.location.href = 'https://alchemydao.com/';
      } else if (network === 'polygon') {
         window.location.href = 'https://polygon.alchemydao.com/';
      } else if (network === 'rinkeby') {
         window.location.href = 'https://rinkeby.alchemydao.com/';
      }
   };

   return (
      <div className='modal'>
         <div className='modal__container--main'>
            <button
               className='modal__exit'
               onClick={() => setConnectModal(false)}
            >
               X
            </button>
            <select
               className='modal__select'
               onChange={e => handleNetwork(e)}
               name='network'
            >
               <option defaultValue value='polygon'>Polygon Mainnet</option>
               <option  value='mainnet'>
                  Ethereum Mainnet
               </option>
               <option value='rinkeby'>Rinkeby Testnet</option>
            </select>
            {!account ? (
               <div className='modal__container--secondary'>
                  <div
                     className='modal__container--secondary-card'
                     onClick={() => handleConnect(connectorsByName.INJECTED)}
                  >
                     <div className='modal__container--secondary-card--metamask'></div>
                     <p>Connect with MetaMask</p>
                  </div>
                  <div
                     className='modal__container--secondary-card'
                     onClick={() =>
                        handleConnect(connectorsByName.WALLETCONNECT)
                     }
                  >
                     <div className='modal__container--secondary-card--walletconnect'></div>
                     <p>Connect with WalletConnect</p>
                  </div>
               </div>
            ) : (
               <div className='modal__container--secondary'>
                  <div
                     className='modal__container--secondary-card'
                     onClick={() => {
                        window.open(`https://etherscan.io/address/${account}`);
                     }}
                  >
                     <p className='modal__account'>Address: {account}</p>
                  </div>
                  <button
                     className='modal__sign-out'
                     onClick={() => deactivate()}
                  >
                     SIGN OUT
                  </button>
               </div>
            )}
         </div>
      </div>
   );
};

export default ConnectModal;
